import endpoints from "../models/api/api.endpoints";
import api from "../helpers/api.service";
import Helper from "../helpers/helper.service";
import ServiceResponseCode from "../models/service.response.code";

export default {
  async setarModoAluno(item) {

    let dados = {
      IdUsuario: item.Id,
      UsuarioNome: item.Nome
    }

    try {
      const url = `${endpoints.salaDeAula}/ativar-modo-aluno`;
      await api.post(url, dados)

      window.localStorage.setItem('idModoAluno', item.Id)
      window.localStorage.setItem('nomeModoAluno', item.Nome)

    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }

  },
  async sairModoAluno() {
    window.localStorage.removeItem('idModoAluno')
    window.localStorage.removeItem('nomeModoAluno')

  },
  checkModoAluno() {
    return (window.localStorage.getItem("idModoAluno")) ? true : false

  },
  getIdModoAluno() {
    return parseInt(window.localStorage.getItem("idModoAluno"))

  },
  getNomeModoAluno() {
    return window.localStorage.getItem("nomeModoAluno")

  },
  async dadosDisciplina(idDisciplina) {
    const params = [
      {
        Name: 'idDisciplina',
        Value: idDisciplina
      },
      {
        Name: 'modoAluno',
        Value: this.getIdModoAluno()
      },
    ]
    try {
      const url = `${endpoints.salaDeAula}/dados-disciplina${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async listaDisciplinasTurma(tipoLista, modalidade) {
    const params = [
      {
        Name: 'modalidade',
        Value: modalidade
      },
      {
        Name: 'tipoLista',
        Value: tipoLista
      },
      {
        Name: 'modoAluno',
        Value: this.getIdModoAluno(),
      },
    ]
    try {
      const url = `${endpoints.salaDeAula}/lista-disciplinas-turma${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async listaItensModulosDisciplina(idDisciplina) {
    try {
      const params = [
        {
          Name: 'idDisciplina',
          Value: idDisciplina
        },
        {
          Name: 'modoAluno',
          Value: this.getIdModoAluno()
        },
      ]
      const url = `${endpoints.salaDeAula}/lista-itens-modulos-disciplina${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  // async listaModulosAulasDisciplina(idDisciplina, modalidade) {
  //   try {
  //     const params = [
  //       {
  //         Name: 'modalidade',
  //         Value: modalidade
  //       },
  //       {
  //         Name: 'idDisciplina',
  //         Value: idDisciplina
  //       },
  //       {
  //         Name: 'modoAluno',
  //         Value: this.getIdModoAluno()
  //       },
  //     ]
  //     const url = `${endpoints.salaDeAula}/lista-modulos-aulas-disciplina${Helper.getURLParams(params)}`;
  //     const result = await api.get(url)
  //     return Helper.getServiceResponse(result)
  //   } catch (e) {
  //     return {
  //       Code: ServiceResponseCode.CatchAPICall,
  //       Title: 'Error',
  //       Message: e.message,
  //       Success: false,
  //     }
  //   }
  // },
  async dadosVisualizacaoAula(idAula) {
    try {
      const params = [
        {
          Name: 'idAula',
          Value: idAula
        },
        {
          Name: 'modoAluno',
          Value: this.getIdModoAluno()
        },
      ]
      const url = `${endpoints.salaDeAula}/dados-visualizacao-aula${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async acompanhamentoAula(dados) {
    try {
      const url = `${endpoints.salaDeAula}/acompanhamento-aula`;
      const result = await api.post(url, dados)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async dadosAtividade(idAtividade, idUsuario) {
    try {
      const params = [
        {
          Name: 'idAtividade',
          Value: idAtividade
        },
        {
          Name: 'idUsuario',
          Value: idUsuario
        },
        {
          Name: 'modoAluno',
          Value: this.getIdModoAluno()
        },
      ]
      const url = `${endpoints.salaDeAula}/dados-atividade${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async dadosEntregaAtividade(idAtividade, idUsuario) {
    try {
      const params = [
        {
          Name: 'idAtividade',
          Value: idAtividade
        },
        {
          Name: 'idUsuario',
          Value: idUsuario
        },
        {
          Name: 'modoAluno',
          Value: this.getIdModoAluno(),
        },
      ]
      const url = `${endpoints.salaDeAula}/dados-entrega-atividade${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async dadosAtividadeUsuario(idAtividade) {
    try {
      const params = [
        {
          Name: 'idAtividade',
          Value: idAtividade
        },
        {
          Name: 'modoAluno',
          Value: this.getIdModoAluno(),
        },
      ]
      const url = `${endpoints.salaDeAula}/dados-atividade-usuario${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async dadosVisualizacaoAtividade(idAtividade) {
    try {
      const params = [
        {
          Name: 'idAtividade',
          Value: idAtividade
        },
        {
          Name: 'modoAluno',
          Value: this.getIdModoAluno(),
        },
      ]
      const url = `${endpoints.salaDeAula}/dados-visualizacao-atividade${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  // async listaModulosAtividades(idDisciplina, modalidade) {
  //   try {
  //     const params = [
  //       {
  //         Name: 'modalidade',
  //         Value: modalidade
  //       },
  //       {
  //         Name: 'idDisciplina',
  //         Value: idDisciplina
  //       },
  //       {
  //         Name: 'modoAluno',
  //         Value: this.getIdModoAluno(),
  //       },
  //     ]
  //     const url = `${endpoints.salaDeAula}/lista-modulos-atividades${Helper.getURLParams(params)}`;
  //     const result = await api.get(url)
  //     return Helper.getServiceResponse(result)
  //   } catch (e) {
  //     return {
  //       Code: ServiceResponseCode.CatchAPICall,
  //       Title: 'Error',
  //       Message: e.message,
  //       Success: false,
  //     }
  //   }
  // },
  async dadosRespostasAtividades(idAtividade) {
    try {
      const params = [
        {
          Name: 'idAtividade',
          Value: idAtividade
        },
      ]
      const url = `${endpoints.salaDeAula}/dados-respostas-atividades${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async updateRespostasAtividades(dados) {
    try {
      const url = `${endpoints.salaDeAula}/update-respostas-atividades`;
      const result = await api.post(url, dados)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async uploadWebcamAtividade(dados) {
    try {
      const url = `${endpoints.salaDeAula}/upload-webcam-atividade`;
      const result = await api.post(url, dados)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async updateAtividadeUsuario(dados) {
    try {
      const url = `${endpoints.salaDeAula}/update-atividade-usuario`;
      const result = await api.post(url, dados)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async registerEntregaAtividade(dados) {
    try {
      const url = `${endpoints.salaDeAula}/entrega-atividade`;
      const result = await api.post(url, dados)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async dadosVisualizacaoAtividadeEntregue(idEntregaAtividade, correcao = false) {
    try {
      const params = [
        {
          Name: 'idEntregaAtividade',
          Value: idEntregaAtividade
        },
        {
          Name: 'modoCorrecao',
          Value: correcao
        },
      ]
      const url = `${endpoints.salaDeAula}/dados-visualizacao-atividade-entregue${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async dadosNotaEntregaAtividade(idEntregaAtividade) {
    try {
      const params = [
        {
          Name: 'idEntregaAtividade',
          Value: idEntregaAtividade
        }
      ]
      const url = `${endpoints.salaDeAula}/dados-nota-entrega-atividade${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async downloadPDF(
    urlArquivo = null
  ) {
    try {
      const params = [
        {
          Name: 'url',
          Value: urlArquivo
        },
      ]
      const url = `${endpoints.salaDeAula}/download-pdf${Helper.getURLParams(params)}`;
      return await api.downloadBlob(url)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },

}
