import endpoints from "../models/api/api.endpoints";
import api from "../helpers/api.service";
import Helper from "../helpers/helper.service";
import ServiceResponseCode from "../models/service.response.code";
import uploadService from "../helpers/upload.service";

export default {
  async caixaEntrada(page = null, pageSize = null, pesquisa= null, modo = null) {
    try {
      const params = [
        {
          Name: 'page',
          Value: page
        },
        {
          Name: 'pageSize',
          Value: pageSize
        },
        {
          Name: 'pesquisa',
          Value: pesquisa
        },
        {
          Name: 'modo',
          Value: modo
        },
      ]
      const url = `${endpoints.mensagem}/caixa-entrada${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async listaCursos() {
    try {
      const url = `${endpoints.mensagem}/lista-cursos`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async listaDisciplinas(pageSize = null, pesquisa = null, idCurso = null) {

    const params = [
      {
        Name: 'pageSize',
        Value: pageSize
      },
      {
        Name: 'pesquisa',
        Value: pesquisa
      },
      {
        Name: 'idCurso',
        Value: idCurso
      },
    ]

    try {
      const url = `${endpoints.mensagem}/lista-disciplinas${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async listaUsuarios(
  page = null,
  pageSize = null,
  pesquisa = null,
  idCurso = null,
  idDisciplina = null) {
    const params = [
      {
        Name: 'page',
        Value: page
      },
      {
        Name: 'pageSize',
        Value: pageSize
      },
      {
        Name: 'pesquisa',
        Value: pesquisa
      },
      {
        Name: 'idCurso',
        Value: idCurso
      },
      {
        Name: 'idDisciplina',
        Value: idDisciplina
      },
    ]
    try {
      const url = `${endpoints.mensagem}/lista-usuarios${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async listaContatos(
  pageSize = null,
  pesquisa = null) {
    const params = [
      {
        Name: 'pageSize',
        Value: pageSize
      },
      {
        Name: 'pesquisa',
        Value: pesquisa
      },
    ]
    try {
      const url = `${endpoints.mensagem}/lista-contatos${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async getMensagem(idMensagem = null) {
    const params = [
      {
        Name: 'idMensagem',
        Value: idMensagem
      },
    ]
    try {
      const url = `${endpoints.mensagem}/mensagem${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async getMensagemUsuario(idMensagemUsuario = null) {
    const params = [
      {
        Name: 'idMensagemUsuario',
        Value: idMensagemUsuario
      },
    ]
    try {
      const url = `${endpoints.mensagem}/mensagem-usuario${Helper.getURLParams(params)}`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async updateMensagens(data, tipo) {
    try {
      const url = `${endpoints.mensagem}/update/${tipo}`;
      const result = await api.post(url, data)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async registerMensagem(data) {
    try {
      const url = `${endpoints.mensagem}/enviar`;
      const result = await api.post(url, data)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async getNotificacaoNaoLidas() {
    try {
      const url = `${endpoints.mensagem}/notificacao-nao-lidas`;
      const result = await api.get(url)
      return Helper.getServiceResponse(result)
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }
  },
  async preUploadBlob(data, file = null) {
    try {

      const url = `${endpoints.mensagem}/pre-upload-mensagem`

      if (file) {
        return await uploadService.uploadFile(url, file, data);

      } else {
        const result = await api.post(url, data)
        return Helper.getServiceResponse(result)

      }
    } catch (e) {
      return {
        Code: ServiceResponseCode.CatchAPICall,
        Title: 'Error',
        Message: e.message,
        Success: false,
      }
    }

  },
}
